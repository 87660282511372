export const menuItems = [
  {
    id: 1,
    title: "Fried Rice & Jollof Assorted",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.33_3ad431fc.jpg?alt=media&token=f9e2e802-1aa9-42ec-a0bf-1909fa23eb75",
    category: "fried rice combos",
    price: 150,
  },

  {
    id: 2,
    title: "Fried Rice and Beef Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.34_ba13e16a.jpg?alt=media&token=94d88dc7-dd83-4c3e-99e5-dab080edcb84",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 3,
    title: "Assorted Fried Rice",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_502bc106.jpg?alt=media&token=e35679da-36b1-40a9-b5a8-0afb2b7f150e",
    category: "fried rice combos",
    price: 100,
  },

  {
    id: 4,
    title: "Assorted Meat Fried Rice",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_502bc106.jpg?alt=media&token=e35679da-36b1-40a9-b5a8-0afb2b7f150e",
    category: "fried rice combos",
    price: 100,
  },

  {
    id: 5,
    title: "Fried Rice & Grilled Chicken",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.35_958daaf8.jpg?alt=media&token=39fecfe5-6f14-494a-bc7d-984e92a48f64",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 6,
    title: "Fried Rice & Gizzard",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0576",
    category: "fried rice combos",
    price: 80,
  },

  {
    id: 7,
    title: "Fried Rice & Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.35_958daaf8.jpg?alt=media&token=39fecfe5-6f14-494a-bc7d-984e92a48f64",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 8,
    title: "Fried Rice & Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0562",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 9,
    title: "Fried Rice & Suya Goat Chops",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0563",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 10,
    title: "Fried Rice & Spicy Wings",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0564",
    category: "fried rice combos",
    price: 75,
  },

  {
    id: 11,
    title: "Beef Fried Rice",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0565",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 12,
    title: "Fried Rice & Grilled Tilapia",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0566",
    category: "fried rice combos",
    price: 120,
  },

  {
    id: 13,
    title: "Fried Rice & Fried Fish",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0567",
    category: "fried rice combos",
    price: 85,
  },

  {
    id: 14,
    title: "Spring Rolls (Veggie or Beef)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0568",
    category: "appetizers",
    price: 30,
  },

  {
    id: 15,
    title: "Samosa (Veggie or Beef)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0569",
    category: "appetizers",
    price: 30,
  },

  {
    id: 16,
    title: "Golden Fried Shrimps",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0570",
    category: "appetizers",
    price: 120,
  },

  {
    id: 17,
    title: "Golden Fried Calamari",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0571",
    category: "appetizers",
    price: 120,
  },

  {
    id: 18,
    title: "Fish Popcorn",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0572",
    category: "appetizers",
    price: 60,
  },

  {
    id: 19,
    title: "Chicken Popcorn",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0573",
    category: "appetizers",
    price: 60,
  },

  {
    id: 20,
    title: "Dynamite Shrimp Popcorn",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0574",
    category: "appetizers",
    price: 100,
  },

  {
    id: 21,
    title: "Egg & Pepper",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0575",
    category: "appetizers",
    price: 10,
  },

  {
    id: 22,
    title: "Goat Light Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0576",
    category: "appetizers",
    price: 50,
  },

  {
    id: 23,
    title: "Fresh Fish Light Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "appetizers",
    price: 50,
  },

  {
    id: 24,
    title: "Assorted Jollof",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_8c782fee.jpg?alt=media&token=6a6d2889-4427-49c1-b73f-693c3fdf559f",
    category: "jollof combos",
    price: 100,
  },

  {
    id: 25,
    title: "Jollof Rice & Grilled Chicken",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.34_7c79e889.jpg?alt=media&token=d09c2965-b47c-4988-a856-621bda32fc1f",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 26,
    title: "Jollof Rice & Gizzard",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.35_f678b79b.jpg?alt=media&token=1871b09e-1874-4487-9b91-a705729641ea",
    category: "jollof combos",
    price: 80,
  },

  {
    id: 27,
    title: "Jollof Rice & Beef Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.35_9417cc5a.jpg?alt=media&token=6b42e09f-b334-4cba-ad56-9c5730010647",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 28,
    title: "Jollof Rice & Fish Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.35_9417cc5a.jpg?alt=media&token=6b42e09f-b334-4cba-ad56-9c5730010647",
    category: "jollof combos",
    price: 100,
  },

  {
    id: 29,
    title: "Jollof Rice & Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 30,
    title: "Jollof Rice & Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 31,
    title: "Jollof Rice & Suya Goat Chops",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_8c782fee.jpg?alt=media&token=6a6d2889-4427-49c1-b73f-693c3fdf559f",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 32,
    title: "Jollof Rice & Spicy Wings",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "jollof combos",
    price: 75,
  },

  {
    id: 33,
    title: "Jollof Rice, Grilled Chicken & Kelewele",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "jollof combos",
    price: 100,
  },

  {
    id: 34,
    title: "Beef Jollof",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_f967cff1.jpg?alt=media&token=aa0c37ea-ad32-4b75-b785-50fe689fabca",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 35,
    title: "Jollof Rice & Grilled Tilapia",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "jollof combos",
    price: 120,
  },

  {
    id: 36,
    title: "Jollof Rice & Fried Fish",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "jollof combos",
    price: 85,
  },

  {
    id: 37,
    title: "Fried Rice & Jollof Assorted",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.33_3ad431fc.jpg?alt=media&token=f9e2e802-1aa9-42ec-a0bf-1909fa23eb75",
    category: "jollof combos",
    price: 150,
  },

  {
    id: 38,
    title: "Yam Chips & Spicy Chicken Wings",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0577",
    category: "yam chips combos",
    price: 85,
  },

  {
    id: 39,
    title: "Yam Chips & Grilled Tilapia",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0578",
    category: "yam chips combos",
    price: 85,
  },

  {
    id: 40,
    title: "Yam Chips & Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0579",
    category: "yam chips combos",
    price: 85,
  },

  {
    id: 41,
    title: "Yam Chips & Suya Goat Chops",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0580",
    category: "yam chips combos",
    price: 85,
  },

  {
    id: 42,
    title: "Yam Chips & Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0580",
    category: "yam chips combos",
    price: 85,
  },

  {
    id: 43,
    title: "Yam Chips & Fried Fis",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0579",
    category: "yam chips combos",
    price: 75,
  },

  {
    id: 44,
    title: "Ga Kenkey & Fried Fish and Eggs",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0580",
    category: "kenkey corner",
    price: 75,
  },

  {
    id: 45,
    title: "Ga Kenkey & Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0581",
    category: "kenkey corner",
    price: 75,
  },

  {
    id: 46,
    title: "Ga Kenkey & Shrimps, Calamari, and Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0582",
    category: "kenkey corner",
    price: 160,
  },

  {
    id: 47,
    title:
      "Ga Kenkey & Fried Eggs, Calamari, Fried Fish, Shrimps, and Avocados",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0583",
    category: "kenkey corner",
    price: 165,
  },

  {
    id: 48,
    title: "Ga Kenkey & Calamari, Shrimps, and Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0584",
    category: "kenkey corner",
    price: 150,
  },

  {
    id: 49,
    title: "Ga Kenkey & Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0585",
    category: "kenkey corner",
    price: 75,
  },

  {
    id: 50,
    title: "Ga Kenkey & Grilled Chicken",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0586",
    category: "kenkey corner",
    price: 75,
  },

  {
    id: 51,
    title: "Ga Kenkey & Sardine, Corned Beef, Fried Eggs, and Fried Fish",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0585",
    category: "kenkey corner",
    price: 100,
  },

  {
    id: 52,
    title: "Banku & Fried Fish and Eggs",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0585",
    category: "banku corner",
    price: 75,
  },

  {
    id: 53,
    title: "Banku & Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0586",
    category: "banku corner",
    price: 75,
  },

  {
    id: 54,
    title: "Banku & Shrimps, Calamari, and Tsofi",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0587",
    category: "banku corner",
    price: 125,
  },

  {
    id: 55,
    title: "Banku & Fried Eggs, Calamari, Shrimps, Fried Fish, and Avocados",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0588",
    category: "banku corner",
    price: 150,
  },

  {
    id: 56,
    title: "Banku & Calamari, Shrimps, and Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0589",
    category: "banku corner",
    price: 120,
  },

  {
    id: 57,
    title: "Banku & Grilled Pork",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0590",
    category: "banku corner",
    price: 75,
  },

  {
    id: 58,
    title: "Banku & Grilled Chicken",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0591",
    category: "banku corner",
    price: 75,
  },

  {
    id: 59,
    title: "Banku & Grilled Tilapia",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0592",
    category: "banku corner",
    price: 100,
  },

  {
    id: 60,
    title: "Banku & Assorted Meat Okro Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0593",
    category: "banku corner",
    price: 100,
  },

  {
    id: 61,
    title: "Banku & Seafood Okro Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0594",
    category: "banku corner",
    price: 150,
  },

  {
    id: 62,
    title: "Indomie Basic (Chicken, Sausages & Gizzard)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.34_444967af.jpg?alt=media&token=c97c6d8a-5166-49a5-8d8a-32289e951b53",
    category: "ghanaian way",
    price: 80,
  },

  {
    id: 63,
    title: "Indomie Loaded (Chicken, Sausages, Beef Pieces, Eggs & Gizzard)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.33_214be13c.jpg?alt=media&token=62254a5b-7653-4913-bf2f-a6ec2ed82857",
    category: "ghanaian way",
    price: 100,
  },

  {
    id: 64,
    title:
      "Indomie Epic (Chicken, Sausages, Beef Pieces, Eggs, Shrimps, Fish Pieces, Gizzard & Veggies)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.33_214be13c.jpg?alt=media&token=62254a5b-7653-4913-bf2f-a6ec2ed82857",
    category: "ghanaian way",
    price: 150,
  },

  {
    id: 65,
    title: "Waakye Basic (Fish/Meat, Wele, Egg, Gari, Spaghetti, Stew)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0595",
    category: "ghanaian way",
    price: 75,
  },

  {
    id: 66,
    title:
      "Waakye Loaded (Fish, Wele, Egg, Gari, Spaghetti, Beef Stew, Sausages, Chicken & Plantain)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0596",
    category: "ghanaian way",
    price: 100,
  },

  {
    id: 67,
    title: "Check Check Basic (Fried Rice with Fried Chicken and Salad)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0597",
    category: "ghanaian way",
    price: 60,
  },

  {
    id: 68,
    title: "Check Check Loaded (Beef Fried Rice with Fried Chicken and Salad)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0598",
    category: "ghanaian way",
    price: 100,
  },

  {
    id: 69,
    title: "Red Red Basic (Egg/Fish)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0599",
    category: "ghanaian way",
    price: 60,
  },

  {
    id: 70,
    title: "Red Red Loaded (Egg & Fish/Chicken/Meat Pieces)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0600",
    category: "ghanaian way",
    price: 80,
  },

  {
    id: 71,
    title: "Chibom (Bread & Egg) & Fried Sausage",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0601",
    category: "ghanaian way",
    price: 60,
  },

  {
    id: 72,
    title: "Beans Loaded with Meat, Egg, and Fried Plantain",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.34_0f673a96.jpg?alt=media&token=4bcfbc8e-6f90-4e93-b85d-3891dce06e9a",
    category: "ghanaian way",
    price: 85,
  },

  {
    id: 73,
    title: "Plain Rice & Corned Beef Stew",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0599",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 74,
    title: "Plain Rice & Sardine and Egg Stew",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.33_48f8d916.jpg?alt=media&token=4079a3d4-3033-460e-870f-ade2d0530ea3",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 75,
    title: "Plain Rice & Grilled Chicken",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0599",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 76,
    title: "Plain Rice & Beef Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0600",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 77,
    title: "Plain Rice & Pork Stew",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0601",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 78,
    title: "Plain Rice & Boiled Eggs, Spaghetti, Beef, and Wele Stew",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0601",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 79,
    title: "Plain Rice & Goat Stew",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0601",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 80,
    title: "Plain Rice & Fried Fish",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0602",
    category: "other rice dishes",
    price: 75,
  },

  {
    id: 81,
    title: "Anguamo Full Loaded",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0603",
    category: "other rice dishes",
    price: 120,
  },

  {
    id: 82,
    title: "Anguamo Basic",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0604",
    category: "other rice dishes",
    price: 85,
  },

  {
    id: 83,
    title: "Suya Goat (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0605",
    category: "extras & sides",
    price: 60,
  },

  {
    id: 84,
    title: "Fish Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0606",
    category: "extras & sides",
    price: 25,
  },

  {
    id: 85,
    title: "Beef Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0607",
    category: "extras & sides",
    price: 15,
  },

  {
    id: 86,
    title: "Chicken Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0608",
    category: "extras & sides",
    price: 20,
  },

  {
    id: 87,
    title: "Goat Kebab",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0609",
    category: "extras & sides",
    price: 15,
  },

  {
    id: 88,
    title: "Pork (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0610",
    category: "extras & sides",
    price: 60,
  },

  {
    id: 89,
    title: "Sauteed Potatoes with Charcoal Grilled Grouper",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0611",
    category: "bougle way",
    price: 180,
  },

  {
    id: 90,
    title: "Curried Rice with Grilled Prawn and Veggie Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0612",
    category: "bougle way",
    price: 180,
  },

  {
    id: 91,
    title: "Plain Rice with Fish and Prawns Veggie Stew",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0613",
    category: "bougle way",
    price: 180,
  },

  {
    id: 92,
    title: "Seafood Noodles",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0614",
    category: "bougle way",
    price: 135,
  },

  {
    id: 93,
    title: "Shrimp Noodles",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0615",
    category: "bougle way",
    price: 180,
  },

  {
    id: 94,
    title: "Vegetable Noodles",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0616",
    category: "bougle way",
    price: 100,
  },

  {
    id: 95,
    title: "Beef Noodles",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0617",
    category: "bougle way",
    price: 100,
  },

  {
    id: 96,
    title: "Creamy Potato Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0618",
    category: "salads",
    price: 60,
  },

  {
    id: 97,
    title: "Garden Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0619",
    category: "salads",
    price: 60,
  },

  {
    id: 98,
    title: "Ghanaian Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0620",
    category: "salads",
    price: 60,
  },

  {
    id: 99,
    title: "Coleslaw",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0621",
    category: "salads",
    price: 35,
  },

  {
    id: 100,
    title: "Shrimp Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0622",
    category: "salads",
    price: 100,
  },

  {
    id: 101,
    title: "Tuna Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0623",
    category: "salads",
    price: 60,
  },

  {
    id: 102,
    title: "Macaroni Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0624",
    category: "salads",
    price: 50,
  },

  {
    id: 103,
    title: "Grilled Chicken Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0625",
    category: "salads",
    price: 75,
  },

  {
    id: 104,
    title: "Egg Salad",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0626",
    category: "salads",
    price: 60,
  },

  {
    id: 105,
    title: "Beef Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0627",
    category: "sauces",
    price: 85,
  },

  {
    id: 106,
    title: "Fish Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0628",
    category: "sauces",
    price: 100,
  },

  {
    id: 107,
    title: "Vegetables Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0629",
    category: "sauces",
    price: 80,
  },

  {
    id: 108,
    title: "Prawn Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0630",
    category: "sauces",
    price: 160,
  },

  {
    id: 109,
    title: "Shrimp Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0631",
    category: "sauces",
    price: 120,
  },

  {
    id: 110,
    title: "Goat Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0632",
    category: "sauces",
    price: 85,
  },

  {
    id: 111,
    title: "Chicken Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0633",
    category: "sauces",
    price: 80,
  },

  {
    id: 112,
    title: "Mixed Seafood Sauce",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0634",
    category: "sauces",
    price: 160,
  },

  {
    id: 113,
    title: "Potato Chips",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0635",
    category: "extras & sides",
    price: 28,
  },

  {
    id: 114,
    title: "Yam Chips (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0636",
    category: "extras & sides",
    price: 30,
  },

  {
    id: 115,
    title: "Tsofi (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0637",
    category: "extras & sides",
    price: 60,
  },

  {
    id: 116,
    title: "Kelewele (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0638",
    category: "extras & sides",
    price: 30,
  },

  {
    id: 117,
    title: "Grilled Chicken (1 Piece)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0639",
    category: "extras & sides",
    price: 15,
  },

  {
    id: 118,
    title: "Calamari (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0640",
    category: "extras & sides",
    price: 100,
  },

  {
    id: 119,
    title: "Shrimp (1 Portion)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0641",
    category: "extras & sides",
    price: 120,
  },

  {
    id: 120,
    title: "Banku Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0642",
    category: "extras & sides",
    price: 8,
  },

  {
    id: 121,
    title: "Kenkey Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0643",
    category: "extras & sides",
    price: 8,
  },

  {
    id: 122,
    title: "Plain Rice Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0644",
    category: "extras & sides",
    price: 30,
  },

  {
    id: 123,
    title: "Jollof Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0645",
    category: "extras & sides",
    price: 30,
  },

  {
    id: 124,
    title: "Waakye Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0646",
    category: "extras & sides",
    price: 30,
  },

  {
    id: 125,
    title: "Fried Rice Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0647",
    category: "extras & sides",
    price: 30,
  },

  {
    id: 126,
    title:
      "Seafood Platter Lobsters, Fish Kebabs, Grilled Tiger Prawns, Calamari & Any Side of Choice",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0648",
    category: "full board platters( serves 5 heads)",
    price: 850,
  },

  {
    id: 127,
    title:
      "Kenkey Platter Suya Pork, Grilled Chicken, Fried Fish, Shrimps, Calamari, Suya Goat Chops, Tsofi, Fried Eggs, Gizzard, Sausages, Beef Strips, Sardine, Corned Beef & Tuna Flakes Choice of 5 Proteins",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0649",
    category: "full board platters( serves 5 heads)",
    price: 650,
  },

  {
    id: 128,
    title:
      "banku platter suya pork, grilled chicken, fried fish, shrimps, calamari, suya goat chops, tsofi, fried eggs, gizzard, sausages, beef & tuna flakes choice of 5 proteins",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0650",
    category: "full board platters( serves 5 heads)",
    price: 650,
  },

  {
    id: 129,
    title:
      "Mixed Meat Platter Chicken Wings, Tsofi, Suya Goat Chop, Pork Bites, Beef Strips, Grilled Sausages & Any Side of Choice",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0651",
    category: "full board platters( serves 5 heads)",
    price: 850,
  },

  {
    id: 130,
    title:
      "Mixed Platter Chicken Wings, Calamari, Tsofi, Grilled Prawns, Fish Kebabs, Suya Goat Chops, Pork Bites, Beef Strips & Any Side of Choice",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0652",
    category: "full board platters( serves 5 heads)",
    price: 1000,
  },

  {
    id: 135,
    title: "Abele Walls Coconut Caramel Chocolate Mango",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0653",
    category: "sweet after meals( desserts)",
    price: 15,
  },

  {
    id: 131,
    title: "Strawberry White Chocolate",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0654",
    category: "sweet after meals( desserts)",
    price: 20,
  },

  {
    id: 132,
    title: "Abele Walls Alcoholic",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0655",
    category: "sweet after meals( desserts)",
    price: 15,
  },

  {
    id: 133,
    title: "Alewa",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0656",
    category: "sweet after meals( desserts)",
    price: 15,
  },

  {
    id: 134,
    title: "Condensed Milk Toffee",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0657",
    category: "sweet after meals( desserts)",
    price: 15,
  },
];
