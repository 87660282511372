import React from "react";
import logo from "../../assets/logo.png";

function Footer() {
  return (
    <footer class="bg-white dark:bg-gray-900 p-12">
      <div class="flex flex-col sm:flex-row items-center justify-between sm:grid-cols-1">
        <img class="w-auto h-20" src={logo} alt="logo" />
        <p class="text-gray-600 text-lg font-bold">Contact us on: 0556608314</p>
      </div>
      <hr class="my-6 border-gray-200 md:my-10 dark:border-gray-700" />
      <div class="flex flex-col items-center text-center">
        <p class="text-sm text-gray-500 dark:text-gray-300">
          ©Copyright 2024. All Rights Reserved.
        </p>
      </div>
    </footer>

    // <footer className="bg-white dark:bg-gray-900">
    //   <div className="container px-6 py-8 mx-auto">
    //     <div className="flex items-center justify-between sm:flex-col">
    //       <img className="w-auto h-20" src={logo} alt="logo" />

    //       <Typography
    //         variant="h4"
    //         color="black"
    //         className="mx-4 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
    //       >
    //         Contact us on: 0556608314
    //       </Typography>
    //     </div>
    //     <hr className="my-6 border-gray-200 md:my-10 dark:border-gray-700" />
    //     <div className="flex flex-col text-center items-center">
    //       <p className="text-sm  text-gray-500 dark:text-gray-300">
    //         © Copyright 2024. All Rights Reserved.
    //       </p>
    //     </div>
    //   </div>
    // </footer>
  );
}

export default Footer;
