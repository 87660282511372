import { useState } from "react";
import FoodSkeleton from "./FoodSkeleton";
import FoodItem from "./FoodItem";
import { Typography } from "@material-tailwind/react";
import { menuItems } from "../../../data/menu";
import { weekendItems } from "../../../data/weekend";

function Menu({ active }) {
  const [menuTab, setMenuTab] = useState("all");

  const today = new Date();
  const day = today.getDay();

  let dayType;
  if (day === 0) {
    dayType = "Weekend";
  } else {
    dayType = "Weekday";
  }

  const filteredFoods =
    menuTab === "all"
      ? dayType === "Weekend"
        ? weekendItems
        : menuItems
      : dayType === "Weekend"
      ? weekendItems.filter((item) => item.category === menuTab)
      : menuItems.filter((item) => item.category === menuTab);

  const handleMenuTabs = (type) => {
    setMenuTab(type);
  };

  return (
    <section id="sectionmenu" className="mb-12 max-w-screen-xl mx-auto px-6">
      <div className="text-center py-8">
        <Typography color="white" variant="h2">
          Our Delicious Menu
        </Typography>
        <Typography variant="small" color="white" className="my-4">
          Savor Every Bite: Explore Our Mouthwatering Creations
        </Typography>
        <p className="flip">
          <span className="deg1"></span>
          <span className="deg2"></span>
          <span className="deg3"></span>
        </p>
      </div>

      {menuItems.length < 1 ? (
        <FoodSkeleton />
      ) : menuItems ? (
        <>
          <div className="flex flex-wrap items-center justify-center space-x-6">
            {dayType === "Weekend"
              ? [
                  "All",
                  "Fufu",
                  "Banku",
                  "Omotuo",
                  "kokonte",
                  "tuo zaafi",
                  "other dishes",
                  "extras",
                  "drinks",
                ]
              : [
                  "All",
                  "Fried Rice combos",
                  "Appetizers",
                  "Jollof Combos",
                  "Yam Chips Combos",
                  "Kenkey Corner",
                  "Banku Corner",
                  "Ghanaian Way",
                  "Other Rice Dishes",
                  "Extras & Sides",
                  "Bougle Way",
                  "Salads",
                  "Sauces",
                  "extras & sides",
                  "Full Board Platters( serves 5 heads)",
                  "Sweet After Meals( desserts)",
                ].map((item) => (
                  <p
                    key={item}
                    className={`my-2 ${
                      menuTab === item.toLowerCase()
                        ? "active_menu_tab bg-primary"
                        : "menu_tab"
                    }`}
                    onClick={() => handleMenuTabs(item.toLowerCase())}
                  >
                    {item}
                  </p>
                ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12">
            {filteredFoods.map((item) => (
              <FoodItem
                key={item.id}
                img={item.img}
                title={item.title}
                price={item.price}
                category={item.category}
                id={item.id}
                active={active}
              />
            ))}
          </div>
        </>
      ) : null}
    </section>
  );
}

export default Menu;
