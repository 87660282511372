import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAA026zORI00OEst94bvnV7FfF5KuQz58c",
  authDomain: "griddle-kitchen-8949d.firebaseapp.com",
  projectId: "griddle-kitchen-8949d",
  storageBucket: "griddle-kitchen-8949d.appspot.com",
  messagingSenderId: "59001081912",
  appId: "1:59001081912:web:deb1a6e7e6d082a9c896cd",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
