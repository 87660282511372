export const weekendItems = [
  {
    id: 1,
    title: "Fufu With Goat & Cow Leg Light Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "fufu",
    price: 85,
    "": "",
  },

  {
    id: 2,
    title: "Fufu With Chicken & Cow Leg Groundnut Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "fufu",
    price: 85,
    "": "",
  },

  {
    id: 3,
    title: "Fufu With Fish Light Soup (Tilapia/Salmon)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "fufu",
    price: 85,
    "": "",
  },

  {
    id: 4,
    title:
      "Fufu With Light/Groundnut Soup - Loaded (Tilapia/Salmon, Cow Leg, Crab, Goat Meat & Salted Beef)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "fufu",
    price: 120,
    "": "",
  },

  {
    id: 5,
    title: "Banku With Okro Soup - Crab, Salmon, Goat Meat & Cow Leg",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "banku",
    price: 85,
    "": "",
  },

  {
    id: 6,
    title: "Banku With Tilapia",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "banku",
    price: 120,
    "": "",
  },

  {
    id: 7,
    title:
      "Banku With Okro Soup - Loaded (Cow Leg, Goat Meat, Fish, Crabs, Wele & Beef)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "banku",
    price: 120,
    "": "",
  },

  {
    id: 8,
    title: "Omotuo With Chicken & Cow Leg Groundnut Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "omotuo",
    price: 85,
    "": "",
  },

  {
    id: 9,
    title:
      "Omotuo With Groundnut Soup - Loaded (Chicken, Cow Leg, Tuna, Boiled Egg)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "omotuo",
    price: 100,
    "": "",
  },

  {
    id: 10,
    title: "Kokonte With Chicken & Cow Leg Groundnut Soup",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "kokonte",
    price: 85,
    "": "",
  },

  {
    id: 11,
    title:
      "Kokonte With Groundnut Soup - Loaded (Chicken, Cow Leg, Tuna, Boiled Egg)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "kokonte",
    price: 100,
    "": "",
  },

  {
    id: 12,
    title: "Tuo Zaafi With Beef, Cow Tripe (Towel) & Fish - Basic",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "tuo zaafi",
    price: 85,
    "": "",
  },

  {
    id: 13,
    title: "Tuo Zaafi With Beef, Cow Tripe (Towel), Cow Leg & Fish - Loaded",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "tuo zaafi",
    price: 100,
    "": "",
  },

  {
    id: 14,
    title: "Jollof with Fried Egg",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "other dishes",
    price: 70,
    "": "",
  },

  {
    id: 15,
    title: "Jollof with Chicken",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "other dishes",
    price: 85,
    "": "",
  },

  {
    id: 16,
    title: "Jollof with Goat Meat",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_8c782fee.jpg?alt=media&token=6a6d2889-4427-49c1-b73f-693c3fdf559f",
    category: "other dishes",
    price: 85,
    "": "",
  },

  {
    id: 17,
    title: "Jollof with Fried Fish",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "other dishes",
    price: 85,
    "": "",
  },

  {
    id: 18,
    title: "Jollof with Cow Meat",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/WhatsApp%20Image%202024-05-23%20at%2010.37.32_f967cff1.jpg?alt=media&token=aa0c37ea-ad32-4b75-b785-50fe689fabca",
    category: "other dishes",
    price: 85,
    "": "",
  },

  {
    id: 19,
    title: "Jollof with Tilapia",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "other dishes",
    price: 100,
    "": "",
  },

  {
    id: 20,
    title: "Banku Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 10,
    "": "",
  },

  {
    id: 21,
    title: "Fufu Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 10,
    "": "",
  },

  {
    id: 22,
    title: "Omotuo Only",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 10,
    "": "",
  },

  {
    id: 23,
    title: "Extra Soup Only (No Meat)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 15,
    "": "",
  },

  {
    id: 24,
    title: "Goat Meat Only (1 Piece)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 15,
    "": "",
  },

  {
    id: 25,
    title: "Fish (Salmon) Only (1 Piece)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 15,
    "": "",
  },

  {
    id: 26,
    title: "Tilapia Only (1 Piece)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 30,
    "": "",
  },

  {
    id: 27,
    title: "Kotodwe (1 Piece)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 10,
    "": "",
  },

  {
    id: 28,
    title: "Creb (1 Piece)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "extras",
    price: 10,
    "": "",
  },

  {
    id: 29,
    title: "Club Beer (Large)",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 25,
    "": "",
  },

  {
    id: 30,
    title: "Origin",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 31,
    title: "Guinness",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 32,
    title: "Asaana",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 33,
    title: "Sobolo",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 34,
    title: "Palmwine",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 35,
    title: "Lamugin",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 36,
    title: "Smirnoff Black",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 25,
    "": "",
  },

  {
    id: 37,
    title: "Local Shots",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 15,
    "": "",
  },

  {
    id: 38,
    title: "Gin Shot",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 20,
    "": "",
  },

  {
    id: 39,
    title: "Black Label Shot",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 40,
    "": "",
  },

  {
    id: 40,
    title: "Carmel Whiskey Shot",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 5,
    "": "",
  },

  {
    id: 41,
    title: "Bottled Water",
    img: "https://firebasestorage.googleapis.com/v0/b/griddle-kitchen-8949d.appspot.com/o/Gemini_Generated_Image_xgwcqsxgwcqsxgwc.jpeg?alt=media&token=79abff4e-eabd-48e4-8378-3fa2502b0560",
    category: "drinks",
    price: 8,
    "": "",
  },
];
